import React from 'react'
import './about.css'
import {MdSchool} from 'react-icons/md'
import { MdComputer } from 'react-icons/md';
import { TbSwimming } from 'react-icons/tb';


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        {/*<div className="about__me">
          <div className='about__me-image'>
            <img src={BildeTrym} alt="ProfilePicture" />
          </div>
        </div>*/}

        <div className="about__content">
          <div className="about__cards">

          <article className='about__card'>
              <MdSchool className='about__icon'/>
              <h5>Academic Credits</h5>
              <small>222.5 Completed</small>
            </article>

            <article className='about__card'>
              <MdComputer className='about__icon'/>
              <h5>Specialization</h5>
              <small>Machine Learning & Optimization</small>
            </article>

            <article className='about__card'>
              <TbSwimming className='about__icon'/>
              <h5>Sports</h5>
              <small>Swimming, Golf</small>
            </article>

          </div>

          <p>
          Hello! I'm Trym Døssland Bjerkvik and am currently pursuing a Master of Science in Informatics at the University of Bergen, with a focus on machine learning and optimization. 
          I did my bachelor's in computer engineering at the Western University of Applied Sciences. During this period, I specialized in machine learning and AI.
          <br/>
          <br/>
          In addition to my academic pursuits, I have also been heavily involved in sports throughout my life. 
          I have a background in swimming and have been a dedicated swimmer for 17 years, competing at a national level for several years. 
          I also have experience in golf and competed when I was younger. 
          I believe that my athletic background has taught me discipline, hard work, and the importance of setting and achieving goals.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About