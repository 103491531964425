import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'

function HeaderSocials() {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/trymbjerkvik/" target='_blank' rel="noreferrer"><BsLinkedin /></a>
        <a href="https://www.instagram.com/trymbjerkvik/" target='_blank' rel="noreferrer"><BsInstagram /></a>
        <a href="https://github.com/bjerkvik" target='_blank' rel="noreferrer"><BsGithub /></a>
    </div>
  )
}

export default HeaderSocials