import React from 'react'
import './testimonials.css'

import {Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/pagination';

import CLIENT1 from '../../assets/clients/client1.jpg'
import CLIENT2 from '../../assets/clients/client2.jpg'
import CLIENT3 from '../../assets/clients/client3.jpg'
import CLIENT4 from '../../assets/clients/client4.jpg'
import CLIENT5 from '../../assets/clients/client5.jpg'

const data = [
  {
    image: CLIENT5,
    name: 'Goofy the Dog',
    review: 'Gawrsh, Trym sure knows his stuff! He\'s got a knack for making learning fun and engaging, and that\'s no easy task!'
  },
  {
    image: CLIENT4,
    name: 'Tweety the Bird',
    review: 'I tawt I taw a brilliant engineer, and I did, I did! Trym\'s commitment and dedication to his work is truly inspiring.'
  },
  {
    image: CLIENT1,
    name: 'Bat the Man',
    review: 'Trym has shown remarkable problem-solving skills - something I greatly value. His innovative approach is commendable.'
  },
  {
    image: CLIENT2,
    name: 'Bean the Mister',
    review: 'Although we have different ways of communicating, I admire Trym\'s clarity and thoroughness. He makes complex ideas easy to understand.'
  },
  {
    image: CLIENT3,
    name: 'Finn the Human',
    review: 'Trym is a real hero when it comes to dealing with any challenge in the world of Machine Learning and AI. He\'s always ready for an adventure in coding!'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true}}>
        
        {
          data.map(({image, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={image} alt={name} />
                </div>
                <h5 className={name}>{name}</h5>
                <small className='client__review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials