import React from 'react'
import { Helmet } from 'react-helmet';
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Courses from './components/Courses/Courses'

const App = () => {
  return (
    <> 
        <Helmet>
            <link rel="canonical" href="https://trymbjerkvik.com/" />
        </Helmet>
        <section id="home"></section>
        <Header />
        <Nav />
        <About />
        <Courses />
        <Experience />
        <Portfolio />
        <Testimonials />
        <Contact />
        <Footer />
    </>
  )
}

export default App