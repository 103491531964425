import React from 'react'
import './footer.css'
import {FaLinkedinIn} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'
import mountain from '../../assets/mountain.png'
import mountainWebp from '../../assets/mountain.webp'

const Footer = () => {
  return (
    <footer>
        <a href="#home" className='footer__logo'>
          <picture>
            <source srcSet={mountainWebp} type="image/webp" />
            <img src={mountain} alt="Logo" />
          </picture>
        </a>

      <ul className='permalinks'>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#courses">Courses</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/trymbjerkvik/"><FaLinkedinIn /></a>
        <a href="https://www.instagram.com/trymbjerkvik/"><FaInstagram /></a>
        <a href="https://github.com/bjerkvik"><FaGithub /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Trym Døssland Bjerkvik. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer