import React from 'react'
import './header.css'
import TrymProfileWebp from '../../assets/BildeTrym.webp'
import TrymProfile from '../../assets/BildeTrym.png'
import HeaderSocials from './HeaderSocials'
/*import CTA from './CTA'*/

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Trym Døssland Bjerkvik</h1>
        <h5 className="text-light">MSc Informatics Student</h5>
        {/*<CTA />*/}
        <HeaderSocials />

        <div className="ProfilePicture">
          <picture>
            <source srcSet={TrymProfileWebp} type="image/webp" />
            <img src={TrymProfile} alt="Trym" />
          </picture>
        </div>

        <a href="#contact" className="scroll__down">Scroll Down</a>

      </div>
    </header>
  )
}

export default Header