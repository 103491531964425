import React from 'react'
import './experience.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'

const frontEndSkills = [
  {
    name: 'HTML',
    proficiency: 'Beginner'
  },
  {
    name: 'CSS',
    proficiency: 'Beginner'
  },
  {
    name: 'JavaScript',
    proficiency: 'Beginner'
  },
  {
    name: 'React',
    proficiency: 'Beginner'
  }
]

const backEndSkills = [
  {
    name: 'Node.js',
    proficiency: 'Beginner'
  },
  {
    name: 'Python',
    proficiency: 'Intermediate'
  },
  {
    name: 'Java',
    proficiency: 'Beginner'
  },
  {
    name: 'SQL',
    proficiency: 'Beginner'
  },
  {
    name: 'KQL',
    proficiency: 'Beginner'
  }

]

const aiSkills = [
  {
    name: 'Python (ML Libraries)',
    proficiency: 'Intermediate'
  },
  {
    name: 'TensorFlow',
    proficiency: 'Beginner'
  },
  {
    name: 'PyTorch',
    proficiency: 'Beginner'
  },
  {
    name: 'fast.ai',
    proficiency: 'Beginner'
  }
]

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

{/* ========== FRONTEND SKILLS ========== */}
      <div className="container experience__container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {
              frontEndSkills.map(({name, proficiency}) => {
                return (
                  <article className='experience__details' key={name}>
                    <BsFillPatchCheckFill className='experience__details-icon' />
                    <div>
                      <h4>{name}</h4>
                      <small className='text-light'>{proficiency}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>

{/* ========== BACKEND SKILLS ========== */}
        <div className="experience_backend">
        <h3>Backend Development</h3>
          <div className="experience__content">
          {
              backEndSkills.map(({name, proficiency}) => {
                return (
                  <article className='experience__details' key={name}>
                    <BsFillPatchCheckFill className='experience__details-icon' />
                    <div>
                      <h4>{name}</h4>
                      <small className='text-light'>{proficiency}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>

{/* ========== MACHINE LEARNING AND AI SKILLS ========== */}
        <div className="experience_backend">
        <h3>Machine Learning and AI</h3>
          <div className="experience__content">
          {
              aiSkills.map(({name, proficiency}) => {
                return (
                  <article className='experience__details' key={name}>
                    <BsFillPatchCheckFill className='experience__details-icon' />
                    <div>
                      <h4>{name}</h4>
                      <small className='text-light'>{proficiency}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience