import React from 'react'
import './courses.css'
import {MdComputer} from 'react-icons/md'
import {GiAtom} from 'react-icons/gi'
import {BiTrendingUp} from 'react-icons/bi'
import {BsCalculator} from 'react-icons/bs'
import {GiChemicalDrop} from 'react-icons/gi'
import {MdOutlineEngineering} from 'react-icons/md'
import {HiOutlineLightBulb} from 'react-icons/hi'
import {BsGraphUp} from 'react-icons/bs'

const DATCourses = [
  {
    code: 'DAT100',
    name: 'Introduction to Programming'
  },
  {
    code: 'DAT102',
    name: 'Algorithms and Data Structures'
  },
  {
    code: 'DAT103',
    name: 'Computers and Operating Systems'
  },
  {
    code: 'DAT107',
    name: 'Databases Systems'
  },
  {
    code: 'DAT108',
    name: 'Programming and web applications'
  },
  {
    code: 'DAT109',
    name: 'Software Development'
  },
  {
    code: 'DAT110',
    name: 'Distributed Systems and Networking Technology'
  },
  {
    code: 'DAT158',
    name: 'Machine Learning Engineering and Advanced Algorithms'
  },
  {
    code: 'DAT191',
    name: 'Bachelor Thesis - Vessel Data Dashboard'
  },
  {
    code: 'DAT255',
    name: 'Deep learning engineering'
  },
  {
    code: 'INF234',
    name: 'Algorithms'
  },
  {
    code: 'INF264',
    name: 'Introduction to Machine Learning'
  },
  {
    code: 'INF265',
    name: 'Deep Learning'
  },
  {
    code: 'INF273',
    name: 'Metaheuristics'
  },
  {
    code: 'INF368',
    name: 'Reinforcement Learning'
  }
];

const otherCourses = [
  {
    code: 'SØK 3520',
    name: 'Microeconomics',
    icon: BiTrendingUp
  },
  {
    code: 'ING102',
    name: 'Engineering Practice and Working Methods (Computing)',
    icon: MdOutlineEngineering
  },
  {
    code: 'MAT101',
    name: 'Discrete Mathematics 1',
    icon: BsCalculator
  },
  {
    code: 'MAT102',
    name: 'Discrete Mathematics and Statistics',
    icon: BsCalculator
  },
  {
    code: 'MAT108',
    name: 'Mathematics for computing',
    icon: BsCalculator
  },
  {
    code: 'ING161',
    name: 'Chemistry for Engineering',
    icon: GiChemicalDrop
  },
  {
    code: 'ING173',
    name: 'Classical mechanics and quantum computing',
    icon: GiAtom
  },
  {
    code: 'ING270',
    name: 'Electricity, Magnetism and semiconductor technology',
    icon: GiAtom
  },
  {
    code: 'ING303',
    name: 'Systems Thinking and Innovation for Engineers',
    icon: HiOutlineLightBulb
  },
  {
    code: 'INF170',
    name: 'Modelling and Optimization',
    icon: BsGraphUp
  },
  {
    code: 'INF270',
    name: 'Linear Programming',
    icon: BsGraphUp
  }
];

const Courses = () => {
  return (
    <section id='courses'>
      <h5>My Academic Journey</h5>
      <h2>Courses</h2>

{/* ========== DAT COURSES ========== */}
      <div className="container courses__container">
        <div className="courses__dat">
          <h3>CS Courses</h3>
          <div className="courses__content">
            {
              DATCourses.map(({code, name}) => {
                return (
                  <article className='course__details' key={code}>
                    <div className="course__details-icon-wrapper">
                    <MdComputer className='course__details-icon' />
                    </div>
                    <div>
                      <h4>{code}</h4>
                      <small className='text-light'>{name}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>

{/* ========== OTHER COURSES ========== */}
        <div className="courses__other">
        <h3>Other Courses</h3>
          <div className="courses__content">
           {
              otherCourses.map(({code, name, icon: Icon}) => {
                return (
                  <article className='course__details' key={code}>
                    <div className="course__details-icon-wrapper">
                    <Icon className='course__details-icon' />
                    </div>
                    <div>
                      <h4>{code}</h4>
                      <small className='text-light'>{name}</small>
                    </div>
                  </article>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Courses