import React, { useRef, useState } from 'react';
import './contact.css'
import emailjs from 'emailjs-com';
import {RiMessengerLine} from 'react-icons/ri'
import {BsSendCheck} from 'react-icons/bs'
import {RiLinkedinBoxLine} from 'react-icons/ri'

const Contact = () => { 

  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
      emailjs.sendForm('service_yiqfbvq', 'template_gmij7mf', form.current, 'jSoLyy-4SkMehgOsf')
      .then((result) => {
          console.log(result.text);
          setMessageSent(true);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()

  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      

      <div className="container contact__container">

        <div className="contact__options">
          <article className="contact__option">
            <RiLinkedinBoxLine className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>Trym Døssland Bjerkvik</h5>
            <a href="https://www.linkedin.com/in/trymbjerkvik/" target='blank'>View Profile</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Trym Døssland Bjerkvik</h5>
            <a href="https://m.me/trymbjerkvik/" target='_empty'>Send a message</a>
          </article>
        </div>

        {/* Remove form and print a confirmation when message is sent */}
        {!messageSent ? (
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required />
            <input type="email" name='email' placeholder='Your Email' required />
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          </form>
        ) : (
          <div className='form-confirmation'> 
            <BsSendCheck />
            <p>Message successfully sent!</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default Contact